<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 20.371 19.919"
  >
    <path
      fill="currentColor"
      d="M20.371 0v3.773a6.225 6.225 0 0 0-2.289 2.113A10.523 10.523 0 0 0 16.9 8.552a11.332 11.332 0 0 0-.4 3.119h3.871v8.248h-8.249v-5.131a21.051 21.051 0 0 1 .905-7.042 14.145 14.145 0 0 1 2.716-4.452A13.379 13.379 0 0 1 20.371 0ZM8.249 0v3.773A6.8 6.8 0 0 0 5.96 5.886a9.61 9.61 0 0 0-1.232 2.666 11.332 11.332 0 0 0-.4 3.119h3.921v8.248H0v-5.131a21.612 21.612 0 0 1 .88-7.042 13.766 13.766 0 0 1 2.691-4.451A13.279 13.279 0 0 1 8.249 0Z"
    />
  </svg>
</template>
